import React from 'react'
import AdComponent from '../AdComponent/AdComponent'

function AboutSection() {
  return (
    <>
        <div>
            <p className='custom-heading h3'>About Play Poki Games</p>
            <div>
                <p className='custom-heading text-start fw-normal'>Play Poki Games is happy to have you aboard, as we aim to create the best browser-based gaming platform in the world. All we want is to make sure that players never have any trouble playing games and also to support all types of developers.</p>
                <p className='custom-heading text-start fw-normal'>Our games are playable at home, at school or on the move, if you are looking for a source of fun you can engage yourself in games that are playable on desktops, tablets and mobile phones. For millions of people around the world, Play Poki Games is their first choice when they feel like gaming online making it the ultimate playground. Moreover, the game is free and open to all.</p>
                { <AdComponent 
                    client="ca-pub-2248973218739803" 
                    slot="6919631838" 
                    format="auto" 
                    style={{ display: 'block', width: '100%', height: '250px' }} 
                /> }
                <p className='custom-heading text-start fw-normal'>Instantly load your favorite games directly in your web browser and enjoy them today at any location.</p>
                <p className='custom-heading text-start fw-normal'>We invite you to come experience limitless gaming on devices ranging from PCs and laptops to Chromebooks, Apple's latest gadgets, and Android devices with us.</p>
            </div>
        </div>
    </>
  )
}

export default AboutSection